import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';

export default () => (
  <DefaultLayout
    bodyClass="thanks"
    pageTitle="Thanks for subscribing"
    pageDescription=""
  >
    <div className="container">
      <section className="center">
        <h1 className="title">We’re excited you’ve subscribed.</h1>
        <p>You’ll be hearing from us soon!</p>
      </section>
    </div>
  </DefaultLayout>
);
